/*FONT DECLARATIONS*/
@font-face {
  font-family: "Messina Sans";
  src: url("../../assets/fonts/Messina-Sans-Regular.otf") format("opentype");
  font-weight: 400;
}
@font-face {
  font-family: "Messina Sans";
  src: url("../../assets/fonts/Messina-Sans-Bold.otf") format("opentype");
  font-weight: 700;
}
@font-face {
  font-family: "Messina Sans";
  src: url("../../assets/fonts/Messina-Sans-Bold.otf") format("opentype");
  font-weight: 200;
}
/**/

/*VIEW ANIMATIONS*/
.fade-enter {
  opacity: 0;
  z-index: 1;
  transition: opacity 400ms ease;
}
.fade-enter.fade-enter-active {
  opacity: 1;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease;
}
.fade-exit-done {
  opacity: 0;
}
/**/

/*ADMIN PANEL*/
.admin-button {
  display: inline-block;
  padding: 0.5em 3em;
  margin:0 0.3em 0.3em 0;
  box-sizing: border-box;
  text-decoration:none;
  text-transform:uppercase;
  font-weight:400;
  text-align:center;
  transition: all 0.15s;
  width: 80%;
}
/**/

/* prevent pull-down-to-refresh of mobile chrome */
body.no-pull-refresh {
  overscroll-behavior-y: contain;
}

/* fullscreen */
html, body, #fs-root {
  height: 100%;
}